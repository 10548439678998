.presentation {
  background-image: url("./img/fondo-init.webp");
  margin-left: -0.1rem;
  margin-top: -0.1rem;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.presentation-p {
  color: var(--white-font);
  font-family: Roboto, sans-serif;
  font-weight: 300;
  font-size: 4rem;
  line-height: 3.1rem;
  text-shadow: 0px 0rem 1rem rgb(0 0 0 / 68%);
  margin-bottom: 8rem;
  letter-spacing: 0.12rem;
  width: auto;
}

@media (max-width: 992px) {
  .presentation {
    display: flex;
    justify-content: space-around;
  }
  .presentation-p {
    font-weight: 300;
    font-size: 2.1rem;
    line-height: 3rem;
    letter-spacing: 0.22rem;
    text-align: center;
    padding-inline: 2rem;
  }
}
