.service {
  display: flex;
  flex-direction: column;
  width: 36rem;
  align-items: center;
}

.service img {
  width: 11rem;
  height: 11rem;
  padding-bottom: 4rem;
}

.service h3 {
  color: var(--gray2);
  padding-bottom: 3rem;
  font-size: 2.8rem;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
}

.service p {
  font-size: 1.8rem;
  color: var(--gray2);
  line-height: 2.6rem;
  text-align: center;
  font-weight: 300;
}

.section-2 {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
}

@media (max-width: 992px) {
  .service img {
    width: 10rem;
    height: 10rem;
    padding-bottom: 2.5rem;
  }

  .service {
    margin-bottom: 8rem;
    width: 80vw;
  }

  .section-2 {
    height: auto;
  }

  .service p {
    font-size: 1.74rem;
    color: var(--gray2);
    line-height: 3rem;
  }

  .service h3 {
    font-size: 2.5rem;
    font-weight: 400;
    padding-bottom: 1.5rem;
  }
}
