.logo-type {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 26rem;
  height: 26rem;
  margin-bottom: 6rem;
}

.name-logo {
  width: 50rem;
  height: 7.8rem;
  margin-bottom: 10rem;
}

@media (max-width: 992px) {
  .logo {
    width: 20rem;
    height: 20rem;
  }

  .name-logo {
    width: 32rem;
  }
}
