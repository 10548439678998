.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 10rem;
}

.footer > p {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 1.9rem;
  letter-spacing: 0.07rem;
  color: white;
  display: flex;
  align-items: center;
}

.p-email img {
  height: 2rem;
  margin-left: 3.5rem;
}

.footer-link {
  color: white;
  font-weight: 500;
  font-size: 1.95rem;
  margin-left: 0.8rem;
}

.footer-link:hover {
  color: #44d2ff;
}

.footer p span {
  font-weight: normal;
  margin-left: 3rem;
}

@media (max-width: 992px) {
  .footer > p {
    font-size: 1.5rem;
    text-align: center;
    line-height: 2.5rem;
    color: #ffffffd1;
  }

  .footer {
    flex-direction: column;
    height: 12rem;
  }

  .footer-link {
    font-size: 1.7rem;
  }
}
