.services {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 9%;
}

.services-title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 6rem;
  line-height: 4.7rem;
  color: var(--blue-font);
  margin-bottom: 10rem;
  align-self: center;
}

.services-group {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

@media (max-width: 992px) {
  .services-title {
    font-weight: 400;
    font-size: 5rem;
    margin-top: 3rem;
    margin-bottom: 6rem;
  }

  .services-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}
